import { Component, OnInit, Input, Output, EventEmitter, NgModule, ViewEncapsulation } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'switch',
    templateUrl: 'switch.component.html',
    styleUrls: ['switch.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf]
})
export class SwitchComponent implements OnInit {
    private _isChecked: boolean = false;
    private _isAutoSet: boolean = true;

    @Input() actionTrackingLabel: string;
    @Input() caption: string;
    @Input() counter:string;
    @Input() set isChecked(value: boolean) {
        if (value !== undefined && this._isChecked !== value) {
            this._isChecked = value;
            if (this.autoSwitchOnClick && !this._isAutoSet) {
                this.isCheckedChange.emit(value);
            }
        }
    }
    get isChecked(): boolean {
        return this._isChecked;
    }
    @Input() autoSwitchOnClick: boolean;
    @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onClickEvent: EventEmitter<void> = new EventEmitter<void>();
    @Input() labelOnRight: boolean;

    constructor() { }

    ngOnInit() { }

    OnClick() {
        this._isAutoSet = false;
        if (this.autoSwitchOnClick) {
            this.isChecked = !this.isChecked;
        }
        else {
            this.onClickEvent.emit();
        }
    }
}


